import React from 'react'
import LoadPanel from 'devextreme-react/load-panel'

export default function Maintenance() {
	return (
		<div>
			<LoadPanel visible={true} />
			<style>
				{`
					.maintenance-container {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						height: 100vh;
						width: 100vw;
						margin-top: -20px;
					}
				`}
			</style>
			<div className="maintenance-container">
				<h4>MAINTENANCE IN PROGRESS</h4>
				<p>
					<br />
					<br />
					Please check back soon.
				</p>
			</div>
		</div>
	)
}
