import 'devextreme/dist/css/dx.common.css'
import './themes/generated/theme.base.css'
import './themes/generated/theme.additional.css'
import './dx-styles.scss'

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import LoadPanel from 'devextreme-react/load-panel'
import { NavigationProvider } from './contexts/navigation'
import { AuthProvider, useAuth } from './contexts/auth'
import { useScreenSizeClass } from './utils/media-query'
import { SharePointProvider, SharePointLoader, SleekPlan } from './components'
import UnauthenticatedContent from './UnauthenticatedContent'
import Maintenance from './Maintenance'
import Content from './Content'
import { socketGet } from './socket'

function App() {
	const socket = socketGet()
	const { user, loading, maintenance, setMaintenance } = useAuth()

	useEffect(() => {
		// Set up maintenance callback for Axios interceptor
		import('./api/auth').then(auth => {
			auth.setMaintenanceCallback(setMaintenance)
		})

		const handlerMaintenance = (payload: any) => {
			setMaintenance(payload.state === 'Started')
		}

		if (socket) {
			socket.on('maintenance', handlerMaintenance)

			return () => {
				socket.off('maintenance', handlerMaintenance)
			}
		}
	}, [socket, setMaintenance])

	if (maintenance) {
		return <Maintenance />
	}

	if (loading) {
		return <LoadPanel visible={true} />
	}

	if (user) {
		return <Content />
	}

	return <UnauthenticatedContent />
}

export default function Root() {
	const screenSizeClass = useScreenSizeClass()

	return (
		<Router>
			<SharePointProvider>
				<SharePointLoader />
				<AuthProvider>
					<NavigationProvider>
						<div className={`app ${screenSizeClass}`}>
							<SleekPlan />
							<App />
						</div>
					</NavigationProvider>
				</AuthProvider>
			</SharePointProvider>
		</Router>
	)
}
