import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { PowerBIEmbed } from 'powerbi-client-react'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { TimestampDisplay } from '../../components'
import { models } from 'powerbi-client'
import axios from 'axios'

export default function ReportDetail() {
	const [text, setText] = useState<string>('')
	const [folder, setFolder] = useState<string>('')
	const [embedUrl, setEmbedUrl] = useState<string>('')
	const [token, setToken] = useState<string>('')
	const [type, setType] = useState<string>('')
	const [refreshTime, setRefreshTime] = useState<null | number>(null)
	const [id, setId] = useState<string>('')
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const report = searchParams.get('report')

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/reports/${report}/detail`)
			.then(response => response.data)
			.then((data: any) => {
				setText(data.text)
				setId(data.config.id)
				setFolder(data.folder)
				setType(data.config.type)
				setEmbedUrl(data.config.EmbedUrl)
				setToken(data.config.EmbedToken.Token)
				setRefreshTime(data.config.RefreshTime)
				document.title = `Fiber :: ${data.text}`
			})
	}, [report])

	return (
		<React.Fragment>
			<div className={'reportContainer'}>
				<h2 className={'content-block'} style={{ margin: '0 10px' }}>
					{type === 'report' &&
						(refreshTime === null ? (
							<div style={{ float: 'right', fontSize: '14px', margin: '12px 6px' }}>
								No successful refresh yet
							</div>
						) : (
							<div style={{ float: 'right', fontSize: '14px', margin: '12px 6px' }}>
								<div style={{ float: 'left', marginRight: '5px' }}>Latest refresh:</div>
								<div style={{ float: 'left' }}>
									<TimestampDisplay timestamp={refreshTime}></TimestampDisplay>
								</div>
							</div>
						))}
					<span className={'dx-icon-chart'} style={{ fontSize: '24px' }}></span>{' '}
					<Link className="link" to={`/reports`}>
						Reports
					</Link>{' '}
					{folder && text && (
						<>
							{' '}
							/{' '}
							<Link className="link" to={`/reports/${folder}`}>
								{folder}
							</Link>{' '}
							/ {text}
						</>
					)}
					&nbsp;
					{!embedUrl && <LoadIndicator height={20} width={20} />}
				</h2>

				{embedUrl && token && id && (
					<PowerBIEmbed
						embedConfig={{
							id: id,
							type: type,
							embedUrl: embedUrl,
							accessToken: token,
							tokenType: models.TokenType.Embed,
							settings: {
								panes: {
									filters: {
										expanded: true,
										visible: true,
									},
								},
								background: models.BackgroundType.Transparent,
							},
						}}
						cssClassName="reportClass"
					/>
				)}
			</div>
		</React.Fragment>
	)
}
