import React, { useEffect, useState, useRef, useCallback } from 'react'
import { socketGet } from '../../socket'
import DataGrid, {
	Column,
	HeaderFilter,
	Selection,
	Paging,
	Editing,
	Button,
	Toolbar as GridToolbar,
	Item as GridItem,
} from 'devextreme-react/data-grid'
import { Button as DxButton } from 'devextreme-react/button'
import { TimestampDisplay, confirmDialog } from '../../components'
import { saveAs } from 'file-saver'
import axios from 'axios'
import notify from '../../utils/notify'

export default function Exports() {
	const [items, setItems] = useState([])
	const [reload, setReload] = useState(true)
	const [selectedItemKeys, setSelectedItemKeys] = useState<string[]>([])
	const socket = socketGet()
	const gridRef = useRef<DataGrid | null>(null)

	useEffect(() => {
		document.title = 'Fiber :: Exports'
	}, [])

	useEffect(() => {
		const handler = () => {
			setReload(true)
		}

		if (socket) {
			socket.on('legacy_export_finished', handler)
			socket.on('legacy_export_error', handler)

			return () => {
				socket.off('legacy_export_finished', handler)
				socket.off('legacy_export_error', handler)
			}
		}
	}, [socket])

	useEffect(() => {
		if (reload) {
			axios
				.get(`${API_FIBER_URL}/exports`)
				.then(response => response.data)
				.then(data => {
					setItems(data.exports)
				})
		}
		setReload(false)
	}, [reload])

	const handleClick = (e: any) => {
		const hash = e.row.data.hash
		axios({
			method: 'get',
			url: `${API_FIBER_URL}/exports/file/${encodeURIComponent(hash)}/download`,
			responseType: 'blob',
		}).then(response => {
			const { data, headers } = response
			const blob = new Blob([data], { type: headers['content-type'] })
			saveAs(blob, e.row.data.file)
		})
	}

	const handleDeleted = (e: any) => {
		axios.delete(`${API_FIBER_URL}/exports/file/${e.data.hash}`)
	}

	const isReady = (e: any) => {
		return !e.row.data.disabled
	}

	const deleteRecords = useCallback(() => {
		if (selectedItemKeys.length === 0) {
			notify('Please select records to delete', 'warning')
			return
		}

		confirmDialog(
			'Are you sure you want to delete these records?',
			`You are about to delete ${selectedItemKeys.length} selected record(s).`
		).then(confirmed => {
			if (confirmed) {
				axios
					.post(`${API_FIBER_URL}/exports/delete-multiple`, {
						hashes: selectedItemKeys,
					})
					.then(response => {
						if (response.data.success) {
							setSelectedItemKeys([])
							setReload(true)
							notify('Records deleted successfully', 'success')
						} else {
							notify('Some records could not be deleted', 'warning')
						}
					})
					.catch(error => {
						console.error('Error deleting records:', error)
						notify('Error deleting records', 'error')
					})
			}
		})
	}, [selectedItemKeys])

	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-download'} style={{ fontSize: '24px' }}></span> Exports
			</h2>
			<div className={'content-block'}>
				<DataGrid
					ref={gridRef}
					dataSource={items}
					rowAlternationEnabled={true}
					showBorders={true}
					width="100%"
					onRowRemoved={handleDeleted}
					selectedRowKeys={selectedItemKeys}
					onSelectionChanged={(e: { selectedRowKeys: string[] }) => {
						if (Array.isArray(e.selectedRowKeys)) {
							setSelectedItemKeys(e.selectedRowKeys)
						}
					}}
					keyExpr="hash"
				>
					<HeaderFilter visible={true} />
					<Editing mode="row" confirmDelete={true} allowDeleting={true} />
					<Paging defaultPageSize={15} defaultPageIndex={0} />
					<Selection mode="multiple" selectAllMode="page" />
					<Column dataField="file" />
					<Column
						dataField="date"
						width="150"
						cellRender={({ data }) => {
							return <TimestampDisplay timestamp={data['time']}></TimestampDisplay>
						}}
					/>
					<Column type="buttons" width="150">
						<Button name="delete" visible={isReady} />
						<Button icon="download" hint="Download" onClick={handleClick} visible={isReady} />
					</Column>
					<GridToolbar>
						<GridItem location="after">
							<DxButton onClick={deleteRecords} icon="trash" text="Delete Selected Records" />
						</GridItem>
					</GridToolbar>
				</DataGrid>
			</div>
		</React.Fragment>
	)
}
