import React, { useState, useEffect, createContext, useContext, useCallback } from 'react'
import { getUser, signIn as apiSignIn, clearAccessToken } from '../api/auth'
import type { User, AuthContextType } from '../types'

function AuthProvider(props: React.PropsWithChildren<unknown>) {
	const [user, setUser] = useState<User>()
	const [loading, setLoading] = useState(true)
	const [maintenance, setMaintenance] = useState(false)

	useEffect(() => {
		getUser()
			.then(result => {
				if (result.isOk) {
					setUser(result.data)
				}
				setTimeout(() => {
					setLoading(false)
				}, 1000)
			})
			.catch(e => {
				setLoading(false)
			})
	}, [])

	const signIn = useCallback(async (code: string) => {
		const result = await apiSignIn(code)
		if (result.isOk) {
			setUser(result.data)
		}

		return result
	}, [])

	const signOut = useCallback(() => {
		setUser(undefined)
		clearAccessToken()
	}, [])

	return <AuthContext.Provider value={{ user, signIn, signOut, loading, maintenance, setMaintenance }} {...props} />
}

const AuthContext = createContext<AuthContextType>({
	loading: false,
	maintenance: false,
} as AuthContextType)

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
